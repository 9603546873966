
import * as React from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import CakeIcon from '@mui/icons-material/Cake';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useState, useRef, useEffect } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com';

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyBfVjWAZRhFGyd8SXJyD1fakxmPx8hOTyQ",
    authDomain: "ianturningsix.firebaseapp.com",
    projectId: "ianturningsix",
    storageBucket: "ianturningsix.appspot.com",
    messagingSenderId: "702767757238",
    appId: "1:702767757238:web:f0e5bd3dcc03d121a53317"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const { width, height } = useWindowSize()

  const [runConfetti, setRunConfetti] = useState(true)
  const timer = setTimeout(stopConfetti, 3000);
  const elementRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [numberOfChildren, setNumberOfChildren] = React.useState('1');
  const [familyName, setFamilyName] = useState("")

  let bg = process.env.PUBLIC_URL + 'images/dino_repeat.png'
  const music = process.env.PUBLIC_URL + 'hb.mp3'

  let image_1 = process.env.PUBLIC_URL + 'images/IMG_1.jpeg'
  let image_2 = process.env.PUBLIC_URL + 'images/IMG_2.jpeg'
  let image_3 = process.env.PUBLIC_URL + 'images/IMG_3.jpeg'
  let image_4 = process.env.PUBLIC_URL + 'images/IMG_4.jpeg'
  let image_5 = process.env.PUBLIC_URL + 'images/IMG_5.jpeg'
  let image_6 = process.env.PUBLIC_URL + 'images/3_year.jpeg'
  let image_7 = process.env.PUBLIC_URL + 'images/4_year.jpeg'
  let image_8 = process.env.PUBLIC_URL + 'images/5_year.jpeg'
  let image_9 = process.env.PUBLIC_URL + 'images/last.jpeg'


  const handleClose = () => {
    setOpen(false);
    setOpenDetails(false)
  };

  const handleMaxWidthChange = (event) => {
    setNumberOfChildren(

      event.target.value,
    );
  };

  const defaultTheme = createTheme();


  function handleSave() {

   
    console.log("number of children: ", numberOfChildren)
    if (!familyName) {
      alert('Please enter your family name and try again.')
      return
    }
    alert("Thank you for your RSVP. Looking forward to see you there!")
    sendEmail()
    setOpen(false)
  }

  function easeOut() {

    const element = elementRef.current;

    if (element) {
      console.log("found element", element)
      element.style.transition = 'opacity 3.0s ease-out';
      element.style.opacity = 0.0;

      // Wait for the transition to end and then remove the element
      const timeoutId = setTimeout(() => {
        element.remove();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }

  function stopConfetti() {

    // setRunConfetti(false)
    easeOut()
    console.log("complete")
  }

  function sendEmail() {
    var templateParams = {
      familyName: familyName,
      numberOfChildren: numberOfChildren
    };

    emailjs.send('service_dghiwwp', 'guests', templateParams, 'user_FmRfhARLFRWibQhB8lF5T')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function (error) {
        console.log('FAILED...', error);
      });
  }

  
  return (

    <ThemeProvider theme={defaultTheme} >
      <CssBaseline />
      <AppBar position="relative" style={{ background: 'rgb(64,113,14)', height: "60px" }} >
        <Toolbar>
          <CakeIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Ian's Birthday Party
          </Typography>
        </Toolbar>
      </AppBar>

      <div ref={elementRef}>
        {runConfetti && (
          <Confetti
            width={width}
            height={height}
            numberOfPieces={500}
            onConfettiComplete={stopConfetti}
          />
        )}
      </div>
      <main style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "repeat",
        height: "100%",
      }}>
        <Container sx={{ py: 8 }} maxWidth="md">
          <Box>
            <Box bgcolor="rgba(64,113,14,0.90)" mb={2} p={3} mt={3} sx={{ textAlign: 'center' }}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="white"
                gutterBottom
              >
                Ian is Turning Six
              </Typography>
              <Typography variant="h5" align="center" color="white" paragraph>
                You are cordially invited to a very special celebration! Ian is turning six, and we would be absolutely thrilled to have you join us for a fun-filled birthday party.
              </Typography>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                <Button color='success' variant="contained" onClick={() => setOpen(!open)}>RSVP</Button>
                <Button color="success" variant="contained" onClick={() => setOpenDetails(true)}>Details</Button>
              </Stack>
            </Box>
          </Box>
          <Container sx={{ py: 4 }} maxWidth="xs">
            <Carousel centerMode={false} autoPlay={true} dynamicHeight={false} infiniteLoop={true}>
              <div>
                <img src={image_1} />

              </div>
              <div>
                <img src={image_2} />

              </div>
              <div>
                <img src={image_6} />

              </div>
              <div>
                <img src={image_7} />

              </div>
              <div>
                <img src={image_8} />

              </div>
            </Carousel>
              <Dialog
                fullWidth={fullWidth}
                open={openDetails}
                onClose={handleClose}
              >
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                   For: Ian's 6th Birthday
                  </DialogContentText>
                  <DialogContentText>
                   Date: 1/20/2024
                  </DialogContentText>
                  <DialogContentText>
                  Time: 2-4pm
                  </DialogContentText>
                  <DialogContentText>
                  Phone: 405.657.9828
                  </DialogContentText>
                  <DialogContentText mt={1} mb={1}>
                  Address: 25566 Rockwell Ave Blanchard, OK 73010
                  </DialogContentText>
                 
                </DialogContent>
                <DialogActions>
                  <Button color={'error'} onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
            {open && (
              <Dialog
                fullWidth={fullWidth}

                open={open}
                onClose={handleClose}
              >
                <DialogTitle>RSVP</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please Enter number of children attending
                  </DialogContentText>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      m: 'auto',
                      width: 'fit-content',
                    }}
                  >
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                      <InputLabel htmlFor="max-width">Children</InputLabel>
                      <Select
                        autoFocus
                        value={numberOfChildren}
                        onChange={handleMaxWidthChange}
                        label="maxWidth"

                      >

                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5+</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      value={familyName}
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Family Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      onChange={(e) => setFamilyName(e.target.value)}

                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button color={'error'} onClick={handleClose}>Cancel</Button>
                  <Button color={'success'} onClick={handleSave}>Submit</Button>
                </DialogActions>
              </Dialog>
            )}
          </Container>
        </Container>
      </main>

    </ThemeProvider >
  );
}

export default App;
